<template>
  <b-row class="flex justify-between pagination-customize flex-nowrap">
    <b-col v-if="meta && isShowEntriesMeta" cols="12" sm="4"
      class="d-flex align-items-center justify-content-center justify-content-sm-start">
      <span class="text-muted">{{ $t('lbl_showing_to_of_entries', { 0: meta.from, 1: meta.to, 2: meta.of }) }}</span>
    </b-col>
    <div cols="12" sm="8" class="flex items-center justify-end gap-4">
      <ShowEntries :perPage="perPage" @setPerPage="handleSetPerPage" />
      <b-pagination v-model="currentPage" :total-rows="totals" :per-page="perPage" first-number last-number
        class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="handleChangeCurrentPage">
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </b-row>
</template>

<script>
import { BCol, BPagination, BRow } from 'bootstrap-vue';
import ShowEntries from '../components/ShowEntries.vue';
export default {
  name: 'Pagination',
  components: { BRow, BCol, BPagination, ShowEntries },
  props: {
    meta: {
      type: Object,
      default: () => { }
    },
    isShowEntriesMeta: {
      type: Boolean,
      default: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totals: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 10
    }
  },
  computed: {},
  methods: {
    handleChangeCurrentPage(value) {
      this.$emit('setCurrentPage', value)
    },
    handleSetPerPage(value) {
      this.$emit('setPerPage', value)
    },
    handleSetCurrentPage(value) {
      this.$emit('setCurrentPage', value)
    }
  }
}
</script>

<style></style>
